import { QueryPrevalidateAdagioSiteNameDomainArgs } from '@src/app/generated/graphql';
import { gql } from 'apollo-angular';
import {
  QueryVideo_SettingsArgs,
  QueryYoutube_VideosArgs,
  VideoSettingTypeConnection,
  YouTubeVideoTypeConnection,
} from '@app/generated/graphql-v2';

interface Result {
  prevalidateAdagioSiteNameDomain: boolean;
}

export const PREVALIDATE_ADAGIO_SITE_NAME_DOMAIN_QUERY = gql<Result, QueryPrevalidateAdagioSiteNameDomainArgs>`
  query validate($bidderId: Int, $name: String, $domain: String) {
    prevalidateAdagioSiteNameDomain(bidderId: $bidderId, name: $name, domain: $domain)
  }
`;

interface VideoSettingListResponse {
  video_settings: VideoSettingTypeConnection;
}
export const VIDEO_SETTING_LIST_QUERY = gql<VideoSettingListResponse, QueryVideo_SettingsArgs>`
  query video_settings($filters: VideoSettingFilter, $order: VideoSettingOrder, $first: Int, $after: String, $site_id: Int!) {
    video_settings(filters: $filters, first: $first, order: $order, after: $after, site_id: $site_id) {
      total_count
      page_info {
        has_next_page
        has_previous_page
        start_cursor
        end_cursor
      }
      edges {
        cursor
        node {
          id
          title
          thumbnail {
            url
          }
          create_date
          channel_id
          mp4_video_content {
            id
          }
          youtube_videos {
            id
            video_id
            title
            thumbnail_url
            video_length
            video_date
          }
          video_generation {
            id
            video_generation_clips {
              id
              order
              url
              title
              image_url
            }
          }
        }
      }
    }
  }
`;

interface YoutubeVideoResponse {
  youtube_videos: YouTubeVideoTypeConnection;
}
export const YOUTUBE_VIDEOS_QUERY = gql<YoutubeVideoResponse, QueryYoutube_VideosArgs>`
  query youtube_videos($filters: YouTubeVideoFilter, $order: YouTubeVideoOrder, $first: Int, $after: String, $site_id: Int!) {
    youtube_videos(filters: $filters, first: $first, order: $order, after: $after, site_id: $site_id) {
      total_count
      page_info {
        has_next_page
        has_previous_page
        start_cursor
        end_cursor
      }
      edges {
        cursor
        node {
          id
          title
          thumbnail_url
          create_date
          video_length
          video_date
        }
      }
    }
  }
`;
